<template>
  <div class="home-container">
    <div class="card">
      <!-- 回复 -->
      <div class="question-cont">
        <ul class="aq-list">
          <li class="aq-item">
            <div class="aq-item-li">
              <div class="question-img">
                <img :src="itemData.live_user_info.avatar == null ? defaultImg : itemData.live_user_info.avatar"
                  class="avatar">
              </div>
              <div class="question-info-cont">
                <div class="time-cont">
                  <div class="tch">{{ itemData.live_user_info.username }}</div>
                  <div class="time">{{ itemData.updated_at | dataformat }}</div>
                </div>
                <div class="question-info-text">{{itemData.content | strformat}}</div>
                <div class="tool-cont">
                  <div class="tool-cont-icon">
                    <img :src="itemData.is_same_ask ? questioned : question" alt=""><span
                      class="time">{{itemData.same_count}}</span>
                  </div>
                  <div>
                    <el-button type="text" @click="handleReply(itemData)">回复</el-button>
                    <el-button type="text" v-if="itemData.user_type" @click="handleDelReply(itemData, 'ques')">删除提问
                    </el-button>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <div v-if="!itemData || listData == {}" class="emptyBox" style="padding: 20px; text-align: center;">暂无数据
          </div>
        </ul>
      </div>

      <div style="padding: 20px;">
        共<span>{{ itemData.reply_number }}条回复</span>
      </div>

      <!-- 回复列表 -->
      <div class="question-list">
        <ul class="aq-list reply-list">
          <li :key="index" class="aq-item" v-for="(item, index) in listData">
            <div class="info-cont">
              <div class="info-cont-img">
                <img :src="item.live_user_info.avatar == null ? defaultImg : item.live_user_info.avatar"
                  class="avatar" />
              </div>
              <div class="info-cont-wrap">
                <div class="tch">{{ item.live_user_info.username }}</div>
                <div class="feed-content">{{item.content | strformat}}</div>
                <div class="tool-cont">
                  <div class="time">{{ item.updated_at | dataformat}}</div>
                  <div class="tool-cont-btn">
                    <div class="tool-cont-btn-icon" @click="clickSame(item)"><img
                        :src="item.is_same_ask ? questioned : question" alt=""><span
                        class="time">{{item.same_count}}</span></div>
                    <el-button type="text" @click="handleReply(item)">回复</el-button>
                    <el-button type="text" class="el-icon-delete btnDel" v-if="item.user_type"
                      @click="handleDelReply(item, 'rep')">
                    </el-button>
                  </div>
                </div>

                <!-- 子级回复列表 * 开始 -->
                <ul class="aq-list reply-list reply-list-item">
                  <li :key="inx" class="aq-item" v-for="(info, inx) in item.reply_data">
                    <div class="info-cont">
                      <div class="info-cont-img">
                        <img :src="info.live_user_info.avatar == null ? defaultImg : info.live_user_info.avatar"
                          class="avatar" />
                      </div>
                      <div class="info-cont-wrap">
                        <div class="tch">
                          <span>{{ info.live_user_info.username }}</span>
                        </div>
                        <div class="feed-content">{{info.content | strformat}}</div>
                        <div class="tool-cont">
                          <div class="time">{{ info.updated_at | dataformat}}</div>
                          <div class="tool-cont-btn">
                            <div class="tool-cont-btn-icon" @click="clickSame(info)"><img
                                :src="info.is_same_ask ? questioned : question" alt=""><span
                                class="time">{{info.same_count}}</span></div>
                            <!-- <el-button type="text" @click="handleReply(info)">回复</el-button> -->
                            <el-button type="text" class="el-icon-delete btnDel" v-if="info.user_type"
                              @click="handleDelReply(info, 'rep')">
                            </el-button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
                <!-- 子级回复列表 * 结束 -->
              </div>
            </div>
          </li>
          <div v-if="!listData || listData.length == []" class="emptyBox"
            style="padding: 10px 20px 40px; text-align: center; color: #999;">暂无数据</div>
        </ul>
        <div v-if="is_reply_flay" style="">
          <el-input class="drawer_reply_input" type="textarea" :rows="5" :placeholder="reply_str" v-model="textarea">
          </el-input>
          <div class="submitBtn-wrap">
            <el-button size="mini" type="success" round class="submitBtn" @click="submit()">回复</el-button>
          </div>
        </div>
        <!-- 分页 -->
        <pag-table class="paginationBox" :total="total" :page="queryParams.page" :limit="queryParams.per_page"
          @changeSize="changeSize" @changePage="changePage" />
      </div>
    </div>
  </div>
</template>

<script>
// 组件-页面
import dayjs from "dayjs";
import pagTable from '@/components/Pagination/pagTable'
// 方法-接口
import { sameAsk, delReply, addReply, getReplyList, getLiveDetailInfo } from "@/api/live";

// 过滤
// 关联类型，0：无关联，1：关联课程，2：关联虚拟仿真
const typeMap = {
  0: '无关联',
  1: '关联课程',
  2: '关联虚拟仿真'
}

export default {
  name: 'replyDetail',
  props: {
    liveSn: {
      type: String,
      default: ""
    },
    replyData: {
      type: Object,
      default: {}
    }
  },
  components: {
    pagTable
  },
  filters: {
    typeFilter(status) {
      return typeMap[status]
    },
    dataformat(value) {
      if (!value) return "";
      return dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss");
    },
    strformat(val) {
      if (!val) return "";
      return val.replace(/<.*?>/ig, "")
    }
  },
  data() {
    return {
      itemData: {},
      queryParams: {
        comment_id: '',
      },
      live_sn: '',
      listData: [],
      flag: false,
      loading: false,
      fillData: {}, // 作用于handleReply中,对帖子做新增回复
      total: 0,
      dialogTitle: '',
      dialogwidth: '50%',
      dialogtype: 'min',
      news: require('@/assets/news.png'),
      question: require('@/assets/question-gray.png'),
      questioned: require('@/assets/questioned_gray.png'),
      defaultImg: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
      is_reply_flay: false,
      textarea: '',
      reply_str: '请输入回复内容',
      id: '',
    }
  },
  watch: {
    replyData: {
      handler(newVal) {
        this.itemData = newVal
        this.queryParams.comment_id = this.itemData.id
        this.live_sn = this.liveSn
        this.getLiveInfo()
      },
      immediate: true,
      deep: true
    }
  },
  computed: {},
  created() { },
  methods: {
    // 初始化列表数据
    initList() {
      this.loading = true
      getReplyList(this.queryParams).then(response => {
        if (response.code === 0 && response.data) {
          this.listData = response?.data?.data ?? []
          this.total = response?.data?.total ?? 0
          this.loading = false
        }
      })
        .catch(error => {
          this.loading = false
        })
    },

    // 分页
    changeSize(data) {
      this.queryParams.per_page = parseInt(data)
      this.initList()
    },
    changePage(data) {
      this.queryParams.page = parseInt(data)
      this.initList()
    },

    // 新增
    handleReply(data) {
      this.reply_str = `回复 ${data.live_user_info.username} :`
      this.is_reply_flay = true
      this.id = data.id
    },
    handleDelReply(data, str) {
      this.delete(data.id, str)
    },
    // 提交
    submit() {
      if (!this.textarea.length) return false
      this.add()
    },

    // 新增
    add() {
      let params = {
        content: this.textarea,
        live_sn: this.live_sn,
        reply_comment_id: this.id
      }
      addReply(params).then(res => {
        if (res.code === 0) {
          this.$message.success('添加回复成功')
          this.is_reply_flay = false
          this.id = ''
          this.textarea = ''
          this.getLiveInfo()
        }
      })
    },

    delete(data, str) {
      this.$confirm('此操作将删除该项数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delReply({ id: data }).then(response => {
            if (response.code === 0) {
              this.$message({
                type: 'success',
                message: '删除成功'
              })
              if (str === 'ques') {
                this.$emit('delReply', true)
              } else if (str === 'rep') {
                this.getLiveInfo()
              }
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    clickSame(data) {
      sameAsk({ id: data.id }).then(res => {
        if (res.code === 0) {
          this.initList()
        }
      })
    },
    async getLiveInfo() {
      try {
        let res = await getLiveDetailInfo({ id: this.itemData.id })
        if (res.code === 0) {
          this.itemData = res.data
          this.initList()
        }
      } catch (error) {

      }
    }
  }
}
</script>
<style lang="scss" scoped>
.home-container {

  .card {
    width: 100%;
    background: #ffffff;
    border-radius: 6px;
    overflow: hidden;
    font-size: 14px;

    .question-cont {
      padding: 20px 20px 10px;
      border-bottom: 1px solid #e8e8e8;

      .aq-item-li {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
      }

      .question-img {
        width: 36px;
        height: 36px;
        margin-right: 10px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 18px;
        }
      }

      .question-info-cont {
        width: calc(100% - 46px);

        .time-cont {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .question-info-text {
          padding: 20px 0px 10px;
          font-size: 16px;
        }

        .tool-cont {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .tool-cont-icon {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          img {
            width: 24px;
            height: 24px;
            margin-right: 8px;
          }
        }
      }
    }

    .question-list {
      padding: 0px 20px;
    }

    .question-cont,
    .question-list {
      .aq-item {
        list-style-type: none;
      }

      .aq-item:not(:first-child) {
        margin-top: 12px;
      }

      .aq-list {
        margin: 0px;
        padding: 0px 10px 0px 20px;
      }

      .paginationBox {
        padding: 0px !important;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }

      .tch,
      .time {
        color: #999;
      }

      .btnDel {
        font-size: 20px;
        color: #999;
      }

    }

    .reply-list {
      .info-cont {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
      }

      .info-cont-img {
        width: 36px;
        height: 36px;
        margin-right: 10px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 18px;
        }
      }

      .info-cont-wrap {
        width: calc(100% - 46px);
      }

      .feed-content {
        padding: 14px 0px 0px;
        font-size: 16px;
      }

      .tool-cont {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .tool-cont-btn {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }

      .tool-cont-btn-icon {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        img {
          width: 24px;
          height: 24px;
          margin-right: 8px;
        }
      }

      .tool-cont-btn-icon+button {
        margin: 0px 8px 0px 14px;
      }
    }

    .reply-list-item {
      margin-top: 12px !important;
      padding: 0px !important;

      .feed-content {
        margin: 0px;
        padding: 0px;
        padding: 14px 0px 0px;
        font-size: 16px;
      }
    }
  }

  .submitBtn-wrap {
    margin: 20px 0px;
    display: flex;
    justify-content: flex-end;
  }

  .submitBtn {
    width: 70px;
    height: 30px;
    background-color: #2BC17B;
    border: #2BC17B;

    span {
      font-size: 14px;
      font-weight: 500;
      color: #FFFFFF;
    }
  }
}
</style>
